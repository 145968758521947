// 修改的样式


blockquote {
    font-family: STKaiti, KaiTi, serif;
    font-style: normal;
}

pre {
    line-height: 16px;
    font-family: consolas, monaco, monospace;
    background-color: #F7F7F9;
}

.post {
    h1, h2, h3, h4, h5, h6 {
        margin-top: 1em;
    }
}

.icarousel {
    margin: 1.5em 0;
}

.slick-prev:before,
.slick-next:before {
    color: #3498db;
}


.text-center {
    text-align: center;
}

.btn {
    font: 500 14px/20px Roboto, sans-serif;
    height: 36px;
    margin: 0;
    min-width: 36px;
    outline: 0;
    overflow: hidden;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    border-radius: 3px;
    background: transparent;
    line-height: 1;
}

.btn-small {
    font-size: .8em;
    height: 28px;
    padding: 6px;
    border-radius: 2px;
}

.btn-default {
    border: 1px solid #333;
    color: #333;
}

.btn-primary {
    border: 1px solid #039be5;
    color: #039be5;
}

.page-content {
    font-family: "Source Han Sans SC", "Hiragino Sans GB", "STHeiti", "PingFang SC",
        "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.post-content p {
    margin: 1.5em 0;
    line-height: 1.8;
}

[data-tooltip], [data-tooltip-img] {
    margin-inline-start: 1px;
    margin-inline-end: 1px;
    padding-block-end: 1px;
    border-block-end: 1px dotted;
    text-decoration: none;
    cursor: help;
}
